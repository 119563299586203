.toast-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
}

.toast {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    min-width: 250px;
    max-width: 300px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;

    &.animate-in {
        opacity: 1;
        transform: translateX(0);
    }

    &.success {
        background: #eafaf1;
        border-left: 4px solid #28a745;
    }

    &.error {
        background: #fdecea;
        border-left: 4px solid #dc3545;
    }

    &.warning {
        background: #fff3cd;
        border-left: 4px solid #ffc107;
    }

    &.info {
        background: #e7f3ff;
        border-left: 4px solid #007bff;
    }
}

.toast-icon {
    font-size: 16px;
    // padding: 5px 10px;
    aspect-ratio: 1;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info .toast-content {
    background-color: #e3f2fd;
    border: 1px solid #90caf9;
    color: #1976d2;
}

.info .toast-icon {
    background-color: #1976d2;
    color: white;
}

.success .toast-content {
    background-color: #e8f5e9;
    border: 1px solid #a5d6a7;
    color: #2e7d32;
}

.success .toast-icon {
    background-color: #2e7d32;
    color: white;
}

.warning .toast-content {
    background-color: #fff3e0;
    border: 1px solid #ffcc80;
    color: #f57c00;
}

.warning .toast-icon {
    background-color: #f57c00;
    color: white;
}

.error .toast-content {
    background-color: #fbe9e7;
    border: 1px solid #ffab91;
    color: #d32f2f;
}

.error .toast-icon {
    background-color: #d32f2f;
    color: white;
}


.toast-message {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.toast-close {
    background: none;
    border: none;
    font-size: 16px;
    color: #666;
    cursor: pointer;
    padding: 4px;

    &:hover {
        color: #333;
    }
}
