@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  font-family: "DM Sans", sans-serif !important;
}
@import "rsuite/dist/rsuite.css";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

.row.rich-text {
  ul > li {
    margin-left: 2rem;
  }

  ol > li {
    margin-left: 2rem;
  }
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: #9e9e9e;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: #9e9e9e;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

/* Hiding the scrollbar and thumb on mobile and tablet screens (max-width: 900px) */
@media only screen and (max-width: 900px) {
  .scrollbar-container .ps__rail-y,
  .scrollbar-container .ps__thumb-y {
    opacity: 0;
    pointer-events: none; /* Disables interaction */
  }
}

/// sidebar scrollbar
#sidebar-scroller,
#onboarding-scroller {
  .ps__rail-x {
    display: none !important;
  }
}

#startup-details-scroller {
  .ps__rail-y {
    display: none !important;
  }
}

.ps__rail-x {
  z-index: 1;
}

svg.saving-icon {
  transition: transform 0.5s ease-in-out;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.info-icon:hover ~ .info-menu {
  display: block;
}

.ql-container {
  border-radius: 5px 5px 0 0;
}
.ql-toolbar {
  border-radius: 0 0 5px 5px;
}

.ql-editor {
  font-size: 1rem;
}
.ql-editor::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.ql-editor::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track (progress bar) */
}

.ql-editor::-webkit-scrollbar-thumb {
  background: #ccc; /* Color of the scroll thumb */
  border-radius: 25px;
}

.ql-editor::-webkit-scrollbar-thumb:hover {
  background: #aaa; /* Color of the scroll thumb on hover */
}

// Table.scss

// Table container
.table-container {
  width: 100%;

  table {
    border-collapse: collapse;
  }

  // Header row
  thead th {
    // background-color: #fffaf5;
    background-color: #eae8fb;
    // border-bottom: 2px solid #eaecf0;
    color: black;
    padding: 0.75rem 1rem;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
  }

  // Body rows
  tbody td {
    border-bottom: 1px solid #ddd;
    padding: 1rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #40454d;
    background-color: #fff;
  }
}

// Button styling
.invite-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}

// Pagination styling
.pagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;

  span {
    margin-right: 10px;
  }

  .select {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
}

//

.character_count {
  height: 1rem;
}

/* Mobile Devices (Portrait, 320px and up) */
@media only screen and (min-width: 320px) {
  .character_count {
    // top: 72% !important;
    bottom: 29% !important;
  }
}

/* Mobile Devices (Portrait, 375px and up) */
@media only screen and (min-width: 375px) {
  .character_count {
    // top: 72% !important;
    bottom: 20% !important;
  }
}

/* Mobile Devices (Portrait, 320px and up) */
@media only screen and (min-width: 425px) {
  .character_count {
    // top: 70% !important;
    bottom: 20% !important;
  }
}

/* Small Devices (Tablets, 600px and up) */
@media only screen and (min-width: 600px) {
  .character_count {
    // top: 70% !important;
    bottom: 20% !important;
  }
}

/* Medium Devices (Tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .character_count {
    // top: 70% !important;
    bottom: 20% !important;
  }
}

@media only screen and (min-width: 900px) {
  .character_count {
    // top: 65% !important;
    bottom: 25% !important;
  }
}

/* Large Devices (Desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .character_count {
    // top: 60% !important;
    bottom: 25% !important;
  }
}

/* Extra Large Devices (Large Desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  div.character_count {
    // top: 64% !important;
    bottom: 25% !important;
  }
}

.quill {
  .ql-toolbar.ql-snow {
    position: absolute !important;
    bottom: 0 !important;
    width: 100%;
    z-index: 1;
    background-color: #fff !important;
  }

  .ql-container.ql-snow {
    padding-bottom: 3.5rem;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ccc;
  }
}

// /* Base font size for the document */
// html {
//   font-size: 16px; /* Default size, typically 16px */
// }

// /* Body text */
// body,
// p,
// li,
// span {
//   font-size: clamp(1rem, 1vw + 0.5rem, 1.25rem);
// }

// /* Headings */
// h1 {
//   font-size: clamp(2rem, 3vw + 1rem, 3rem);
// }

// h2 {
//   font-size: clamp(1.75rem, 2.5vw + 1rem, 2.5rem);
// }

// h3 {
//   font-size: clamp(1.5rem, 2vw + 0.75rem, 2rem);
// }

// h4 {
//   font-size: clamp(1.25rem, 1.5vw + 0.75rem, 1.5rem);
// }

// h5 {
//   font-size: clamp(1rem, 1vw + 0.5rem, 1.25rem);
// }

// h6 {
//   font-size: clamp(0.875rem, 0.75vw + 0.5rem, 1rem);
// }

// /* Subheadings / Small text */
// small {
//   font-size: clamp(0.75rem, 0.5vw + 0.25rem, 0.875rem);
// }

// /* Navigation / Buttons */
// a,
// button {
//   font-size: clamp(1rem, 1vw + 0.5rem, 1.25rem);
// }

// /* Captions / Footnotes */
// caption,
// figcaption {
//   font-size: clamp(0.75rem, 0.5vw + 0.25rem, 0.875rem);
// }

/////
///
.skeleton {
  width: 100%; /* Adjust width as needed */
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px; /* Optional: Rounds the corners */
}

.permissions-skeleton-layout {
  display: grid;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;

  .skeleton-checkbox {
    width: 18px;
    height: 18px;
    border-radius: 3px;
  }

  .skeleton-label {
    height: 14px;
  }

  .checkbox-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 1rem;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0 !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #a1a3a7 !important;
}

.MuiTabs-root > .Mui-disabled.MuiTabs-scrollButtons {
  display: none !important;
  transition: all 1s ease;
}

// onboarding layout styles ---- start
.container {
  display: flex;
  max-width: 1200px;
  max-height: 960px;
  border-radius: 12px;
  // overflow: hidden;
  width: 100%;
  height: 95vh;
  margin: auto;

  .form-section {
    padding: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .illustration-section {
    flex: 1;
    background-image: url("./assets/Backrr\ -\ Investor\ Banner.png");
    background-size: contain;
    background-position: left top;
    position: relative;
    display: none;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    .illustration-section {
      display: block !important;
    }
  }
}

@media screen and (min-height: 600px) and (min-width: 600px) {
  .container {
    overflow: hidden;
  }
}

// onboarding layout styles ---- end

#onboarding-scroller::-webkit-scrollbar {
  display: none;
  width: 5px; /* Width of the scrollbar */
}

#onboarding-scroller::-webkit-scrollbar-thumb {
  background: #ccc; /* Color of the scroll thumb */
  border-radius: 25px;
}

.report-scroller::-webkit-scrollbar {
  display: none;
  width: 5px; /* Width of the scrollbar */
}

.report-scroller::-webkit-scrollbar-thumb {
  background: #ccc; /* Color of the scroll thumb */
  border-radius: 25px;
}

.MuiAccordionSummary-root
  .Mui-expanded
  > .MuiAccordionSummary-content
  .Mui-expanded {
  margin-block: "0 !important";
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
  grid-gap: 0.9rem;
}

.item {
  min-height: 1.5rem;
  border: #007bff 1px solid;
  border-radius: 0.6rem;
  padding: 0.3rem 0.3rem;
  margin-bottom: 0.6rem;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: rgba(36, 102, 255, 0.1);
  color: #948fb5;
}

.selected-item {
  background-color: #007bff;
  color: #fff;
}

#feedback-scrollbar::-webkit-scrollbar {
  display: none;
  width: 5px; /* Width of the scrollbar */
}

#feedback-scrollbar::-webkit-scrollbar-thumb {
  background: #ccc; /* Color of the scroll thumb */
  border-radius: 25px;
}

.filter-autocomplete > .MuiAutocomplete-inputRoot.MuiInputBase-root {
  padding-block: 6.5px;
  border-radius: 8px;
  background-color: #fff;
  fieldset {
    border-color: #ebe8fb !important;
    border-radius: 8px;
  }
}

#scrollable-form {
  .ps__rail-x {
    display: none;
  }
}

.report-read-only {
  .rs-input-group-disabled,
  .rs-input:disabled {
    background-color: #fff !important;
  }

  .MuiFilledInput-input.Mui-disabled {
    background: #fff !important;
  }
}

.MuiFilledInput-input {
  border-radius: 4px;
}

.avatar-investor {
  border-radius: 4px !important;
}

.investment-focus {
  background: radial-gradient(#ffffff, #f1e5d6);
}

#users-settings-table > div {
  height: calc(100vh - 16rem) !important;
}

.startups-listing > .Mui-Grid {
  height: min-content;
}

#permissions-scrollbar {
  .ps__rail-x {
    display: none !important;
  }
}

.snackccc .MuiSnackbarContent-root {
  background-color: #fff;
  color: #333;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
  font-family: Arial, sans-serif;

  .snackbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .snackbar-details {
    font-size: 14px;
    color: #666;
    margin-top: 4px;
  }

  .snackbar-action {
    padding: 4px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-transform: none;
    font-weight: bold;
    font-size: 14px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .snackbar-action:hover {
    background-color: #e0e0e0;
  }
}

.ql-container.ql-snow.ql-disabled {
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.38);
}

.ql-container.ql-snow.ql-disabled:hover {
  background-color: white;
  cursor: not-allowed;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled {
  background-color: #e0e0e0;
  &:hover {
    background-color: white;
    cursor: not-allowed;
  }
}

.release_notes_editor {
  .character_count {
    bottom: 30% !important;
  }
}

// .ql-container.ql-snow.ql-disabled ~ .character_count {
//   display: none;
// }
